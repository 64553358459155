import { Observable, of, /*throwError*/ } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Utils } from 'src/app/services/utils';

import { MpnModel } from '../models/MpnModel';
import { Action } from '../models/Action';
import { PapiHttpService } from '../services/papi-http.service';
import { MpnCheckService } from '../services/mpn-check.service';

@Component({
  selector: 'sk-mpn-registration',
  templateUrl: './mpn-registration.component.html',
  styleUrls: ['./mpn-registration.component.scss']
})
export class MpnRegistrationComponent implements OnInit {
  // Copied from the C# side.
  minMpnLength = 6;
  maxMpnLength = 7;

  // Form references. There are two forms on this page.
  mpnForm = new FormGroup({
    mpn: new FormControl()
  });
  addressForm = new FormGroup({
    organizationName: new FormControl(),
    address1: new FormControl(),
    address2: new FormControl(),
    city: new FormControl(),
    stateCode: new FormControl(),
    zipCode: new FormControl(),
    countryOrRegion: new FormControl(),
    phoneNumber: new FormControl(),
    languagePreferenceCode: new FormControl(),
    technicalContactEmail: new FormControl()
  });

  // Debugging helper.
  serviceName = "MpnRegistrationComponent";   // even if minified we can log this

  // Form control values.
  mpnValidationInProgress = false;
  mpnValid = false; // whether or not the MPN part of the form is valid
  addressValid = false; // whether or not the address form is valid
  showValidation = false; // whether or not we show messages to the user about their validation situation
  showThrottlingMessage = false; // whether or not we show a message to the user that they have tried to validate too many times

  // Main model object.
  model: MpnModel;

  constructor(
    private mpnCheckService: MpnCheckService,
    private papiService: PapiHttpService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    const self = this;
    this.resetModel();

    // Double check we really should be on this page. This will bring the user to the next step if they don't need to be.
    const sessionMpnRequired = self.mpnCheckService.getMpnRequiredFromSession();
    self.mpnCheckService.performCheck(sessionMpnRequired, true).subscribe(action => {
      // Perform check sets some session variables (mpnRequired, sessionRequired).
      if (action !== Action.GoToAccountSetupPage) {
        self.mpnCheckService.run(action);
      }

      // If we haven't been redirected to the homepage, build the form out.
      self.buildForms(this.mpnCheckService.getAddressRequiredFromSession())
    });
  }

  resetModel() {
    this.model = {
      mpn: "",
      organizationName: "",
      address1: "",
      address2: "",
      city: "",
      stateCode: "",
      zipCode: "",
      countryOrRegion: "",
      phoneNumber: "",
      technicalContactEmail: "",
      languagePreferenceCode: ""
    };
  }

  // Build out the form groups.
  buildForms(addressRequired: boolean) {

    // The MPN form.
    this.mpnForm = this.fb.group({
      mpn: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(7)])
    });

    // The address information form.
    this.addressForm = this.fb.group({
      organizationName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', ],
      city:  ['', Validators.required],
      stateCode:  ['', Validators.required],
      zipCode:  ['', Validators.required],
      countryOrRegion:  ['', Validators.required],
      phoneNumber:  ['', ],
      languagePreferenceCode:  ['', Validators.required],
      technicalContactEmail:  ['', Validators.required]
    });

    // PAPI can return back that address is not required.
    if (!addressRequired) {
      this.debug("buildForms", "clearing validators because address is not required");
      this.addressForm.clearValidators();
    }
  }

  // Patch the form with what we get back from PAPI.
  patchForm() {
    // this.addressForm.setValue(data); throws an error because there are more fields on the model than the form,
    // so patch the values individually.
    this.addressForm.patchValue({
      organizationName: this.model.organizationName,
      address1: this.model.address1,
      address2: this.model.address2,
      city: this.model.city,
      stateCode: this.model.stateCode,
      zipCode: this.model.zipCode,
      countryOrRegion: this.model.countryOrRegion,
      phoneNumber: this.model.phoneNumber,
      languagePreferenceCode: this.model.languagePreferenceCode,
      technicalContactEmail: this.model.technicalContactEmail
    });
  }

  // Grabs data from the two forms and puts it into the model.
  private setModel() {
    for (const key in this.model) {
      const formValue = this.tryGetFormValue(key);
      // Selectively overwrite parts of the model with what is on the form.
      // The model has extra properties not on the form from an earlier API call.
      if (formValue) {
        this.model[key] = formValue;
      }
    }
    this.debug("setModel", JSON.stringify(this.model));
  }

  // Helper for trying to get the form value equivalents of each item on the model.
  tryGetFormValue(key: string) {
    let result = "";
    let formElement = this.mpnForm.controls[key];
    if (formElement) {
      let value = formElement.value;
      if (value) {
        console.debug(value);
        result = value.trim();
      }
    } else {
      // For example, id is not on the form...
    }
    return result;
  }

  // Called whenever the user changes the MPN.
  mpnChange() : void {
    const formValue = this.mpnForm.controls["mpn"].value;
    this.debug("mpnChange", "mpn is now " + formValue);
    this.mpnValid = false;
    this.model.mpn = formValue;

  }

  // Check the MPN is valid.
  validateMpn() {
    const self = this;
    self.debug("validateMpn", "enter");

    // Note: This logic was ported from PortalV1 and slightly modified.
    if (!self.model.mpn) {
      self.debug("validateMpn", "exit early it is empty");
      self.mpnValid = false;
      self.showValidation = true;
      self.mpnValidationInProgress = false;
      return;
    }

    self.mpnValidationInProgress = true;
    const mpnBeforeRequest = self.model.mpn;
    self.debug("validateMpn", "calling getMpnData(" + this.model.mpn + ")");
    self.papiService.getMpnData(this.model.mpn).subscribe({
      next(response) {
        self.mpnValidationInProgress = false;
        if (response) {
          self.debug("validateMpn", "received a good response from getMpnData");
          // This was taken from PortalV1. After this moment there are more fields on the model than before. For example, id, mpnProfileUrl, lastValidationTime, etc.
          self.model = response;

          // Response comes back with mpnNumber not mpn, that's why this is necessary.
          self.model.mpn = mpnBeforeRequest;

          self.patchForm();

          // Set the form variables.
          self.mpnValid = true;
          self.showValidation = false;
          self.showThrottlingMessage = false;
        }
        else {
          self.debug("validateMpn", "received a bad response from getMpnData");

          // User shouldn't be able to get to this state...
          // The form controls that are required are all required, and its disabled on submit,
          // so show a error modal.
          self.mpnCheckService.showGenericErrorModal();

          // Set the form variables.
          self.mpnValid = false;
          self.showValidation = true;
          self.showThrottlingMessage = false;
        }
      },
      error(err) {
        self.mpnValid = false;

        if (err && err.message.toLowerCase().indexOf('too many requests') !== -1) {
          // 429 Too Many Requests case.

          // This is odd it shows MPN Invalid and also the throttling messages...
          // but really we don't know if it is valid or not, because there were too many requests.
          // This is how it worked on PortalV1 so we are keeping it on PortalV2.
          self.showThrottlingMessage = true;
        }
        self.showValidation = true;
        self.mpnValidationInProgress = false;
      },
      complete() {
        self.debug("validateMpn", "complete");
      }
    });
  }

  // Submit the form.
  submitAddressForm() {
    const self = this;
    self.setModel();
    self.papiService.saveMpnRegistrationInformation(self.model).subscribe(response => {
      // We may get back a 204, no content.
      if (!response) {
        self.mpnCheckService.setMpnRequiredInSession(false);
        self.mpnCheckService.setAddressRequiredInSession(false);
        self.mpnCheckService.goToHomepage();
      }
    }, (err) => {
      self.mpnCheckService.showGenericErrorModal();
      self.error("submit", err);
    });
  }

  // Log the error to the console.
  private error(context: string, errorInfo: any) {
    Utils.logError(`${this.serviceName} ERROR in ${context}`, errorInfo);
  }

  // Log debugging info to the console.
  private debug(methodName: string, debugInfo: any) {
    Utils.logDebug(`${this.serviceName}.${methodName}: `, debugInfo);
  }
}
